<template>
  <div>
    <RWCard>
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.934 16.072l6.426-5.116.158-.125a1.5 1.5 0 00.003-2.35l-6.587-5.238a1.5 1.5 0 00-1.868 0L4.475 8.485a1.5 1.5 0 00-.001 2.347l.156.124 6.436 5.117a1.5 1.5 0 001.868 0z" fill="#000" fill-rule="nonzero"/><path d="M11.056 18.67l-5.72-4.547a.903.903 0 00-1.276.154.91.91 0 00.166 1.279l6.861 5.247a1.5 1.5 0 001.823 0l6.858-5.243a.915.915 0 00.167-1.288.91.91 0 00-1.286-.155l-5.725 4.554a1.5 1.5 0 01-1.868 0z" fill="#000" opacity=".3"/></g></svg>
          Lightsail Products
        </h3>
      </template>
      <template v-slot:body>
        <div v-if="loading.banner">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </div>
        <div v-else>
          <b-carousel
            v-if="xhr.bannerSuccess"
            id="carousel-fade"
            style="text-shadow: 0 0 2px #000"
            fade
            indicators
            img-width="1024"
            img-height="300"
          >
            <b-carousel-slide
              v-for="(row, key) in xhr.banner"
              :key="key"
              :caption="row.caption"
              :img-src="row.image"
            ></b-carousel-slide>
          </b-carousel>
          <div class="alert alert-danger" v-else>
            {{ xhr.banner }}
          </div>
        </div>
      </template>
    </RWCard>
    <b-card>
      <template v-slot:header>
        <b-form inline @submit.stop.prevent="search">
          <label class="sr-only">Domain Name</label>
          <b-input
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="eq. mydomain.id"
            v-model="form_search.domain"
          ></b-input>

          <label class="sr-only">Username</label>
          <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0"
            required
            :options="status"
            v-model="form_search.status"
          ></b-form-select>

          <b-button variant="primary" type="submit">Find</b-button>
        </b-form>
      </template>

      <!-- table -->
      <div v-if="loading.table">
        <b-skeleton-table
          :rows="pagination.limit"
          :columns="5"
          :table-props="{ striped: true }"
          v-if="loading.pagination"
        ></b-skeleton-table>
      </div>
      <div v-else>
        <div class="alert alert-danger" v-if="!xhr.lightsailSuccess">
          {{ xhr.lightsail }}
        </div>
        <div v-else>
          <ShowProduct :data="xhr.lightsail" type="lightsail"></ShowProduct>
          <!-- pagination -->
          <b-pagination
            v-model="pagination.current"
            :total-rows="pagination.allCount"
            :per-page="pagination.limit"
            @change="handlePagination"
            align="right"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
          ></b-pagination>
        </div>
      </div>
      <!-- endtable -->
    </b-card>
  </div>
</template>

<script>
import ShowProduct from "@/views/pages/product/slot/slotProductShow";
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
// import { ApiURL, util } from "@/common/mixins/general.js";

export default {
  components: {
    ShowProduct,
    RWCard
  },
  data() {
    return {
      xhr: {
        banner: [],
        bannerSuccess: false,
        lightsail: [],
        lightsailSuccess: false
      },
      loading: {
        banner: true,
        table: true
      },
      form_search: {
        domain: null,
        status: null
      },
      pagination: {
        limit: 3,
        offset: 0,
        current: 1,
        allCount: null
      },
      status: ["All", "Active", "Suspended", "Cancelled", "Terminated"]
    };
  },
  methods: {
    populateBanners() {
      let banners = [
        {
          key: "SampleKey1",
          caption: "",
          image: require("@/assets-dummy/images/banner_1.jpg")
        },
        {
          key: "SampleKey2",
          caption: "",
          image: require("@/assets-dummy/images/banner_2.jpg")
        },
        {
          key: "SampleKey3",
          caption: "",
          image: require("@/assets-dummy/images/banner_3.jpg")
        }
      ];

      this.xhr.banner = banners;
      this.xhr.bannerSuccess = true;
      this.loading.banner = false;
    },
    populateLightsails() {
      let lightsails = [
        {
          id: "1",
          domain: "AWS-0001",
          name: "AWS Linux Nano",
          status: this.status[1],
          recurring: "Annually",
          duedate: "2022-02-02",
          price: "1.500.000,00"
        },
        {
          id: "2",
          domain: "AWS-0002",
          name: "AWS Linux Micro",
          status: this.status[2],
          recurring: "Annually",
          duedate: "2022-05-11",
          price: "900.000,00"
        },
        {
          id: "3",
          domain: "AWS-0003",
          name: "AWS Windows Nano",
          status: this.status[4],
          recurring: "Annually",
          duedate: "2022-07-21",
          price: "2.225.000,00"
        },
        {
          id: "4",
          domain: "AWS-0004",
          name: "AWS Linux Large",
          status: this.status[3],
          recurring: "Annually",
          duedate: "2022-09-14",
          price: "24.300.000,00"
        }
      ];

      let offset =
        this.pagination.current * this.pagination.limit - this.pagination.limit;
      let limit = this.pagination.current * this.pagination.limit;

      this.pagination.allCount = lightsails.length;
      lightsails = lightsails.slice(offset, limit);
      this.xhr.lightsail = lightsails;
      this.xhr.lightsailSuccess = true;
      this.loading.table = false;
    },

    /**
     * ------------------------------------------------
     * handle pagination next/prev, sesuaikan kembali
     * setelah data diambil dari server
     * ------------------------------------------------
     */
    handlePagination(page) {
      this.loading.pagination = true;
      this.pagination.current = page;
      this.pagination.offset = this.pagination.current * this.pagination.limit;
      this.populateLightsails();
    }
  },
  mounted() {
    this.populateBanners();
    this.populateLightsails();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product", route: "product" },
      { title: "Lightsail", route: "product/lightsail" }
    ]);
  }
};
</script>

<style lang="scss" scoped></style>
